import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LayoutService} from '../../../core/services/layout.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {Bio} from '../../../core/models/bio.model';
import {select, Store} from '@ngrx/store';
import {selectCurrentBio} from '../../../store/bios/bios.selectors';
import {AppState} from '../../../store';

export class SidebarNavItem {
  public route: string;
  public label: string;

  constructor(route: string, label: string) {
    this.route = route;
    this.label = label;
  }
}


@Component({
  selector: 'app-secured-sidebar',
  templateUrl: 'secured-sidebar.component.html',
  styleUrls: ['secured-sidebar.component.css']
})
export class SecuredSidebarComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription;

  @Input()
  navIsVisible: boolean;

  public biosIsVisible: boolean;
  public jarsIsVisible: boolean;
  public supportIsVisible: boolean;
  public accountIsVisible: boolean;

  public bio: Bio;

  constructor(
    protected router: Router,
    protected store: Store<AppState>,
    protected layoutService: LayoutService
  ) {

    this.subscription = new Subscription();

    this.subscription.add(this.store.pipe(select(selectCurrentBio))
      .subscribe((bio: Bio) => {
        this.bio = bio;
      })
    );

    this.subscription.add(layoutService.SidebarStatus
      .subscribe((status: boolean) => {
        this.navIsVisible = status;
      }, (error) => {

      })
    );

    // Open the sub-nav if we're on one of the sub-nav pages
    this.biosIsVisible = this.isLinkActive('/profiles');
    this.jarsIsVisible = this.isLinkActive('/jars');
    this.supportIsVisible = this.isLinkActive('/support');
  }

  public ngOnInit() {
  }

  public showNav() {
    this.navIsVisible = this.layoutService.showSidebar();
  }

  public hideNav() {
    this.navIsVisible = this.layoutService.hideSidebar();
  }

  public openLink() {
    window.open('https://mylifejars.com/pricing-and-features/', '_blank');
  }

  public protectLink() {
    window.open('https://protect.mylifejars.com/login', '_blank');
  }

  public toggleNav() {
    this.navIsVisible = this.layoutService.toggleSidebar();
  }

  public toggleDashboard(event){
    event.preventDefault();
    this.biosIsVisible = false;
    this.jarsIsVisible = false;
    this.supportIsVisible = false;
    this.accountIsVisible = false;
    this.hideNav();
  }

  public toggleBios(event) {
    event.preventDefault();
    this.biosIsVisible = !this.biosIsVisible;
    this.accountIsVisible = false;
    this.jarsIsVisible = false;
    this.supportIsVisible = false;
  }

  public toggleJars(event) {
    event.preventDefault();
    this.jarsIsVisible = !this.jarsIsVisible;
    this.accountIsVisible = false;
    this.biosIsVisible = false;
    this.supportIsVisible = false;
  }

  public toggleSupport(event) {
    event.preventDefault();
    this.supportIsVisible = !this.supportIsVisible;
    this.accountIsVisible = false;
    this.jarsIsVisible = false;
    this.biosIsVisible = false;
  }

  public toggleAccount(event) {
    event.preventDefault();
    this.accountIsVisible = !this.accountIsVisible;
    this.supportIsVisible = false;
    this.jarsIsVisible = false;
    this.biosIsVisible = false;
  }

  public isLinkActive(url): boolean {
    const queryParamsIndex = this.router.url.indexOf('?');
    const baseUrl = queryParamsIndex === -1
      ? this.router.url
      : this.router.url.slice(0, queryParamsIndex);

    if (url.length > 1) { // if url != '/'
      return baseUrl.startsWith(url);
    }
    return baseUrl === url;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
