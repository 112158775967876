<aside class="sidebar sidebar-left sidebar-left-1" [ngClass]="{'shown' : navIsVisible }">
  <div class="sidebar-inset">
    <ul class="nav-tree">

      <li class="nav-branch">
        <a routerLink="/" routerLinkActive="nav-active" [routerLinkActiveOptions]="{exact:true}" (click)="toggleDashboard($event)">
          <div class="sprite sprite-home"></div>
          <div class="text">Dashboard</div>
        </a>
      </li>

      <li class="nav-branch">
        <a [ngClass]="{'nav-active':biosIsVisible}" routerLinkActive="nav-active" (click)="toggleBios($event)">
          <div class="sprite sprite-tribe"></div>
          <div class="text">Tribe</div>
        </a>

        <ul class="nav-tree" [ngClass]="{'collapsed':!biosIsVisible}">
          <li class="nav-branch">
            <a routerLink="/profiles/life-wall" routerLinkActive="nav-active" (click)="hideNav()">
              <div class="sprite sprite-life-wall"></div>
              <div class="text">Life Wall</div>
            </a>
          </li>
          <li class="nav-branch">
            <a routerLink="/profiles/remembrance-wall" routerLinkActive="nav-active" (click)="hideNav()">
              <div class="sprite sprite-bios"></div>
              <div class="text">Remembrance Wall</div>
            </a>
          </li>
          <li class="nav-branch">
            <a routerLink="/profiles/wards" routerLinkActive="nav-active" (click)="hideNav()">
              <div class="sprite sprite-wards"></div>
              <div class="text">Wards</div>
            </a>
          </li>
          <li class="nav-branch">
            <a routerLink="/profiles/guardians" routerLinkActive="nav-active" (click)="hideNav()">
              <div class="sprite sprite-guardians"></div>
              <div class="text">Guardians</div>
            </a>
          </li>
        </ul>

      </li>

      <li *ngIf="bio" class="nav-branch">
        <a [ngClass]="{'nav-active':jarsIsVisible}" (click)="toggleJars($event)">
          <div class="sprite sprite-jars"></div>
          <div class="text">Jars</div>
        </a>

        <ul class="nav-tree" [ngClass]="{'collapsed':!jarsIsVisible}">

          <li *ngIf="bio.memoryJars.length > 0 && (bio.permissions.memoryJars > 0 || bio.isOwner)" class="nav-branch">
            <a routerLink="/jars/memories" routerLinkActive="nav-active" (click)="hideNav()">
              <div class="sprite sprite-jars-memories"></div>
              <div class="text">Memory Jars</div>
            </a>
          </li>

          <li *ngIf="bio.thingJars.length > 0 && (bio.permissions.thingsJars > 0 || bio.isOwner)" class="nav-branch">
            <a routerLink="/jars/things" routerLinkActive="nav-active" (click)="hideNav()">
              <div class="sprite sprite-jars-things"></div>
              <div class="text">Thing Jars</div>
            </a>
          </li>

          <li *ngIf="bio.passwordJars.length > 0 && (bio.permissions.passwordJars > 0 || bio.isOwner)" class="nav-branch">
            <a routerLink="/jars/passwords" routerLinkActive="nav-active" (click)="hideNav()">
              <div class="sprite sprite-jars-passwords"></div>
              <div class="text">Password Jars</div>
            </a>
          </li>

        </ul>

      </li>


      <!--
      <li class="nav-branch">
        <a routerLink="/wishes" routerLinkActive="nav-active" (click)="hideNav()">
          <div class="sprite sprite-wishes"></div>
          <div class="text">Wishes</div>
        </a>
      </li>

      <li class="nav-branch">
        <a routerLink="/wisdom" routerLinkActive="nav-active" (click)="hideNav()">
          <div class="sprite sprite-wisdom"></div>
          <div class="text">Wisdom</div>
        </a>
      </li>
      -->

      <!--
      <li class="nav-branch">
        <a routerLink="/support" routerLinkActive="nav-active"  (click)="hideNav()">
          <div class="sprite sprite-support"></div>
          <div class="text">Support</div>
        </a>
      </li>
      -->

      <li class="nav-branch">
        <a [ngClass]="{'nav-active':supportIsVisible}" routerLinkActive="nav-active" (click)="toggleSupport($event)">
          <div class="sprite sprite-support"></div>
          <div class="text">Support</div>
        </a>

        <ul class="nav-tree" [ngClass]="{'collapsed':!supportIsVisible}">
          <li class="nav-branch">
            <a href="https://mylifejars.com/tutorials/" target="_blank" routerLinkActive="nav-active" (click)="hideNav()">
              <div class="sprite sprite-tutorials"></div>
              <div class="text">Tutorials</div>
            </a>
          </li>

          <li class="nav-branch">
            <a href="https://mylifejars.com/how-it-works/#app" target="_blank" routerLinkActive="nav-active" (click)="hideNav()">
              <div class="sprite sprite-faqs"></div>
              <div class="text">FAQs</div>
            </a>
          </li>
        </ul>

      </li>


      <li class="nav-branch">
        <a [ngClass]="{'nav-active':accountIsVisible}" routerLinkActive="nav-active" (click)="toggleAccount($event)">
          <div class="sprite sprite-settings"></div>
          <div class="text">Account</div>
        </a>

        <ul class="nav-tree" [ngClass]="{'collapsed':!accountIsVisible}">
          <li class="nav-branch">
            <a  routerLink="/settings" routerLinkActive="nav-active" (click)="hideNav()">
              <div class="sprite sprite-setting"></div>
              <div class="text">Settings</div>
            </a>
          </li>

          <!-- <li class="nav-branch">
            <a routerLink="/settings" routerLinkActive="nav-active" (click)="hideNav()">
              <div class="sprite sprite-account-change"></div>
              <div class="text">Change</div>
            </a>
          </li>

          <li class="nav-branch">
            <a routerLink="/settings" routerLinkActive="nav-active" (click)="hideNav()">
              <div class="sprite sprite-billing"></div>
              <div class="text">Billing</div>
            </a>
          </li> -->

          <li class="nav-branch">
            <a routerLinkActive="nav-active" (click)="openLink()">
              <div class="sprite sprite-account-change"></div>
              <div class="text">Change</div>
            </a>
          </li>

          <li class="nav-branch">
            <a  routerLinkActive="nav-active" (click)="protectLink()">
              <div class="sprite sprite-billing"></div>
              <div class="text">Billing</div>
            </a>
          </li>



        </ul>

      </li>

    </ul>
  </div>
</aside><!-- /sidebar-1 -->

<div class="mobile-sidebar-overlay" (click)="hideNav()"></div>

