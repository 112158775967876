import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {faEye, faEyeSlash, faQuestionCircle, faUsers,faClipboard,faCopy} from '@fortawesome/free-solid-svg-icons';
import {AlertService} from '../../../components/alert/alert.service';
import {TribesterPickerComponent} from '../../pickers/tribster/tribester-picker.component';
import {MemoryModel} from '../../../../core/models/memory.model';
import {filter, first} from 'rxjs/operators';
import {MemoryService} from '../../../../core/services/memory.service';
import {FileUploaderComponent} from '../../../components/file-uploader/file-uploader.component';
import {Bio} from '../../../../core/models/bio.model';
import {JarModel} from '../../../../core/models/jar.model';
import {ConfirmPopupComponent} from '../../confirm-popup/confirm-popup.component';
import {ResponseMessage} from '../../../../core/models/response-message.model';
import {LayoutService} from '../../../../core/services/layout.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store';
import {BioActions} from '../../../../store/bios';
import {Subscription} from 'rxjs';
import {OptionsState} from '../../../../store/options/options.state';
import {MemoryActions, MemorySelectors} from '../../../../store/memories';
import {OptionSelectors} from '../../../../store/options';
import {UserSubscription} from '../../../../core/models/user-subscription.model';
import {ControlValidationService} from '../../../components/control-validation/control-validation.service';
import {PasswordModel} from '../../../../core/models/password.model';
import {UserSelectors} from '../../../../store/users';
import {PasswordsActions, PasswordsSelectors} from '../../../../store/passwords';

@Component({
  templateUrl: 'password-edit-modal.component.html'
})
export class PasswordEditModalComponent implements OnInit, OnDestroy {
  protected readonly subscription: Subscription;
  protected CONCEPT_KEYWORD: string;

  public readonly DESCRIPTION_LENGTH = 5000;

  public faEye = faEye;
  public faEyeSlash = faEyeSlash;
  public faClipboard = faClipboard;
  public faCopy = faCopy;
  public faQuestionCircle = faQuestionCircle;
  public faUsers = faUsers;

  @ViewChild(FileUploaderComponent)
  public fileUploader: FileUploaderComponent;

  @ViewChild('secretKeeper')
  public secretKeeper: ElementRef;

  @ViewChild('timeLock')
  public timeLock: ElementRef;

  @Input()
  public bio: Bio;

  @Input()
  public memory: MemoryModel;
  public password: PasswordModel;

  public editForm: FormGroup;
  public submitted = false;
  public showPassword = false;
  protected debounceTimer;

  public jar: JarModel;
  public optionsData: OptionsState;
  public userSubscription: UserSubscription;

  // convenience getter for easy access to form fields
  public get f() {
    return this.editForm.controls;
  }

  public get MemoryJarTypes(): JarModel[] {
    return this.bio.passwordJars;
  }

  public get SecretKeeperTypes() {
    return this.optionsData.secretKeeperTypes;
  }

  public get TimeLockTypes() {
    return this.optionsData.timeLockTypes;
  }

  constructor(
    public activeModal: NgbActiveModal,
    protected formBuilder: FormBuilder,
    protected modalService: NgbModal,
    protected store: Store<AppState>,
    protected memoryService: MemoryService,
    protected layoutService: LayoutService,
    protected alertService: AlertService
  ) {
    this.CONCEPT_KEYWORD = 'Password';

    this.jar = new JarModel();
    this.memory = new MemoryModel();
    this.password = new PasswordModel();

    this.subscription = new Subscription();
    this.optionsData = new OptionsState();
    this.subscription.add(this.store.pipe(select(OptionSelectors.selectOptions))
      .subscribe((options: OptionsState) => {
        this.optionsData = options;
      })
    );

    this.subscription.add(this.store.pipe(
      select(UserSelectors.selectCurrentUserSubscription)
      )
        .subscribe((userSub: UserSubscription) => {
          this.userSubscription = userSub;
        })
    );

  }

  public ngOnInit() {

    this.editForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(250)]],
      description: ['', [Validators.maxLength(this.DESCRIPTION_LENGTH)]],
      parentNode: [null, Validators.required], // aka Memory Jar
      secretKeeper: [5, Validators.required],
      timeLock: [2, Validators.required],

      username: ['', [Validators.maxLength(250)]],
      password: ['', [Validators.maxLength(250)]],
      url: ['', [Validators.maxLength(250)]],
      email: ['', [ControlValidationService.emailValidator, Validators.maxLength(250)]],
      mobile: ['', [Validators.maxLength(250)]],
      accountId: ['', [Validators.maxLength(250)]]
    });

    // The Memory Password field is an object (we don't want that)
    if (this.memory && this.memory.password) {
      this.memory.password = null;
    }
    this.editForm.patchValue(this.memory);

    // Existing Memory so Subscribe to the store
    if (this.memory.id) {

      this.subscription.add(this.store
        .pipe(
          select(MemorySelectors.selectCurrentMemory),
          filter((memory: MemoryModel) => memory != null)
        )
        .subscribe((memory: MemoryModel) => {
          this.memory = memory;
          this.editForm.patchValue(this.memory);

          // If there is an existing password, we'll retrieve it
          if (this.memory.passwordId != null) {
            this.store.dispatch(PasswordsActions.getCurrentPassword({
              id: this.memory.passwordId
            }));
          }

        })
      );

      this.subscription.add(this.store.pipe(
        select(PasswordsSelectors.selectCurrentPassword)
        )
          .subscribe((password: PasswordModel) => {
            if (password == null) {
              this.password = new PasswordModel();
            } else {
              this.password = password;
            }
            this.editForm.patchValue(this.password);
          })
      );

    }

    // Update the jars on Init
    this.onJarChange(null);
  }

  public onJarChange(event) {
    const jarId = this.editForm.get('parentNode').value;
  }


  public enableTribeEditor(){
    const item: number = this.editForm.get('secretKeeper').value;
    return item === 4; // Tribester Only
  }

  public showTribeEditor(event){
    if (this.enableTribeEditor() === false) {
      return;
    }

    const modal = this.modalService.open(TribesterPickerComponent, {backdrop: 'static'});
    modal.componentInstance.bio = this.bio;
    modal.componentInstance.tribesters = [ ...[], ...this.memory.tribesters ];
    modal.result.then((tribesters: Bio[]) => {
      this.memory = {
        ...this.memory,
        ...{
          tribesters
        }
      };
      this.secretKeeper.nativeElement.focus();
    }, (reason) => {
      this.secretKeeper.nativeElement.focus();
    });
  }

  public onSecretKeeperChange(event) {
    event.target.blur(); // Without this we get exception: ExpressionChangedAfterItHasBeenCheckedError
    if (this.enableTribeEditor() === false) { // Tribester Only
      return;
    }
    return this.showTribeEditor(event);
  }


  public onTimeLockChange(event) {
    event.target.blur();
    // event.preventDefault();
  }

  public onShowPassword(event) {
    this.showPassword = !this.showPassword;
  }

  public delete(event: any) {
    event.preventDefault();

    const modal = this.modalService.open(ConfirmPopupComponent, {size: 'sm', backdrop: 'static'});
    modal.componentInstance.title = 'Delete ' + this.CONCEPT_KEYWORD;
    modal.componentInstance.message = 'Are you sure you want to delete this ' + this.CONCEPT_KEYWORD + '?';
    modal.componentInstance.classes = 'danger';
    modal.result.then((result) => {
      this.store.dispatch(
        MemoryActions.deleteMemoryRequest({
          memory: this.memory
        })
      );
      this.activeModal.close(null);
    }, (reason) => {

    });
  }


  public onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.editForm.invalid) {
      return;
    }

    const formData = this.editForm.getRawValue();

    const model = {
      ...this.memory, ...{
        title: formData.title,
        description: formData.description,
        parentNode: formData.parentNode,
        secretKeeper: formData.secretKeeper,
        timeLock: formData.timeLock,
        memoryTag: 17, // Other: We need a full list
        password: {
          ...this.password,
          ...{
            accountId: formData.accountId,
            username: formData.username,
            password: formData.password,
            url: formData.url,
            email: formData.email,
            mobile: formData.mobile
          }
        }
      }
    };

    this.memoryService.saveMemory(model)
      .pipe(first())
      .subscribe(
        (responseMessage: ResponseMessage) => {
          const memory = responseMessage.data;

          this.store.dispatch(
            MemoryActions.saveMemoryRequestSuccess({
              responseMessage
            })
          );

          // Increase Bio Stats (if its a new memory)
          if (!this.memory.id) {
            this.store.dispatch(
              BioActions.incrementCurrentBioMemoryCounter({memory})
            );
          }

          this.dismissModal(memory);
        });
  }

  public dismissModal(data: any = null) {
    // Clear any stored passwords.
    this.store.dispatch(
      PasswordsActions.setCurrentPassword({password: null})
    );
    this.activeModal.close(data);
  }

  public copyToClipboard(value: string) {
    console.log('Value to copy:', value); 
    if (!value) {
      console.error('No value provided for clipboard copy');
      return;
    }
  
    navigator.clipboard.writeText(value).then(() => {
      
      this.alertService.success('Copied to clipboard successfully!');
    }, err => {
      this.alertService.error('Could not copy text: ', err);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
