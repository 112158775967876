<div class="modal-header">
  <h4 class="modal-title">TIME TO CHANGE PLANS</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-body-plan-upgrade">

  <h4 class="text-center">You've reached the limits of your My LifeJar's Basic Free Plan</h4>

  <p>
    Stay within the Basic Account limits by editing or deleting sample memories, 
    things or passwords. Upgrade to a Gold account for the ability to add more content, 
    including video files up to 500 MB.
  </p>

  <app-user-plan-stats-box [user]="user" [userSubscription]="userSubscription" [bio]="bio"></app-user-plan-stats-box>

  <!-- <p>
    When changing from a free or monthly plan to an annual plan
    you receive a 10% saving and can select one of My LifeJars Partners to receive a 10%
    donation from your subscription.
  </p> -->

  <p class="text-center">
    <a class="btn btn-lg btn-gold"   (click)="featureLink()"  (click)="activeModal.dismiss('Cross click')">
      Change Account
    </a>
  </p>


</div>
