<app-page-header title="Account Settings"></app-page-header>

<div class="container settings-container ">
  <div class="row">
    <div class="col-12">

      <div class="row">
        <div class="col-md-8">

          <div class="card card-bio">
            <div class="card-body p-sm-5">

                <h2 class="mb-4">Email</h2>

                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <input readonly type="text" class="form-control form-control-lg input-bio disabled"
                             placeholder="Email" [value]="user.email"/>
                    </div>

                    <div class="form-group mt-4">
                      <button type="button" class="btn btn-lg btn-block btn-gold max300-center"
                              (click)="changeEmailModal($event)">
                        <fa-icon [icon]="faUserEdit"></fa-icon>
                        Change email
                      </button>
                    </div>
                  </div>
                </div>

            </div>

          </div><!--/card -->




          <div class="card card-bio mt-5">
            <div class="card-body p-sm-5">
              <div class="row">
                <div class="col-12">
                  <h2>Additional Security - Two Factor Authentication (2FA)</h2>
                </div>
              </div>

              <a id="2fa" name="2fa"></a>
              <div class="row mt-2">
                <div class="col-sm-6">

                  <div class="custom-control custom-switch custom-switch-xl">
                    <input type="checkbox" class="custom-control-input" id="enable2Fa"
                           [(ngModel)]="enable2Fa" (ngModelChange)="onLoginSettingsChange()">
                    <label class="custom-control-label" for="enable2Fa">
                      <div>Enable 2FA</div>
                    </label>
                  </div>

                </div>
                <div class="col-sm-6 mt-5 mt-sm-0">

                  <div class="custom-control custom-switch custom-switch-xl">
                    <input type="checkbox" class="custom-control-input" id="rememberMe"
                           [(ngModel)]="rememberMe" (ngModelChange)="onLoginSettingsChange()">
                    <label class="custom-control-label" for="rememberMe">
                      <div>Stay logged in for 30 days</div>
                    </label>
                  </div>

                </div>
              </div>
            </div>
          </div><!--/card -->

          <div class="card card-bio mt-5">
            <div class="card-body p-sm-5">
              <div class="row">
                <div class="col-12">
                  <h2>Change Password</h2>
                </div>
              </div>

              <a id="password" name="password"></a>
              <div class="row">
                <div class="col-12">

                  <form-validation [control]="passwordForm" [submitted]="showPasswordFormErrors"></form-validation>
                  <form [formGroup]="passwordForm" (ngSubmit)="onPasswordSubmit()">

                    <div class="form-group">
                      <label class="control-label sr-only">Old Password</label>
                      <input type="password" formControlName="oldPassword"
                             class="form-control form-control-lg input-bio"
                             [ngClass]="{ 'is-invalid': showPasswordFormErrors && f2.oldPassword.errors }"
                             placeholder="Old Password"/>
                      <app-control-validation [control]="f2.oldPassword"
                                              [submitted]="showPasswordFormErrors"></app-control-validation>
                    </div>

                    <div class="form-group">
                      <label class="control-label sr-only">Password</label>
                      <input type="password" formControlName="password" class="form-control form-control-lg input-bio"
                             [ngClass]="{ 'is-invalid': showPasswordFormErrors && f2.password.errors }"
                             placeholder="New Password"/>
                      <app-control-validation [control]="f2.password"
                                              [submitted]="showPasswordFormErrors"></app-control-validation>
                    </div>

                    <password-strength-meter [password]="f2.password.value"></password-strength-meter>

                    <div class="form-group">
                      <label class="control-label sr-only">Confirm Password</label>
                      <input type="password" formControlName="passwordConfirm"
                             class="form-control form-control-lg input-bio"
                             [ngClass]="{ 'is-invalid': showPasswordFormErrors && f2.passwordConfirm.errors }"
                             placeholder="Confirm New Password"/>
                      <app-control-validation [control]="f2.passwordConfirm"
                                              [submitted]="showPasswordFormErrors"></app-control-validation>
                    </div>

                    <div class="form-group mt-4">
                      <button class="btn btn-lg btn-block btn-gold max300-center">
                        Change password
                      </button>
                    </div>

                  </form>

                </div>
              </div>
            </div>
          </div><!--/card -->



          <div class="card card-bio mt-5">
            <div class="card-body p-sm-5">

              <form-validation [control]="userForm" [submitted]="showUserFormErrors"></form-validation>
              <form [formGroup]="userForm" (ngSubmit)="onSubmit()">

                <h2 class="mb-4">Account Information</h2>

                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label">First Name</label>
                      <input type="text" formControlName="firstName" class="form-control form-control-lg input-bio"
                             [ngClass]="{ 'is-invalid': showUserFormErrors && f.firstName.errors }"
                             placeholder="First name"/>
                      <app-control-validation [control]="f.firstName"
                                              [submitted]="showUserFormErrors"></app-control-validation>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label">Last Name</label>
                      <input type="text" formControlName="lastName" class="form-control form-control-lg input-bio"
                             [ngClass]="{ 'is-invalid': showUserFormErrors && f.lastName.errors }"
                             placeholder="Last Name"/>
                      <app-control-validation [control]="f.lastName"
                                              [submitted]="showUserFormErrors"></app-control-validation>
                    </div>
                  </div>
                </div>


                <!-- <h2 class="mt-5">Billing</h2>
                <div class="form-group">
                  <app-common-address formControlName="addressDetails" [withTypeField]="false"
                                      [submitted]="showUserFormErrors"></app-common-address>
                  <app-control-validation [control]="f.addressDetails"
                                          [submitted]="showUserFormErrors"></app-control-validation>
                </div> -->

                <div class="form-group mt-4">
                  <button class="btn btn-lg btn-block btn-gold max300-center">
                    Update
                  </button>
                  
                </div>

              </form>
            </div>
          </div><!--/card -->

        </div><!-- /col-md-8 -->

        <div class="col-md-4 mt-5 mt-md-0">

          <div class="card card-bio">
            <div class="card-body p-sm-5">

              <h2>Active Features</h2>
              <ul class="list list-subscription-details" *ngIf="userSubscription">
                <li class="item item-detail">
                  <div class="list-subscription-details__logo my-4">
                    <img src="/assets/img/mylifejars-logo-sm-inverse.png" alt="My LifeJars">
                  </div>

                  <h4 [innerHTML]="userSubscription.title | stripHtml"></h4>
                  <p class='centered' [innerHTML]="userSubscription.shortDescription"></p>
                </li>
                <li>
                  <app-user-plan-stats-box [user]="user" [userSubscription]="userSubscription"
                                           [bio]="bio"></app-user-plan-stats-box>
                </li>
                <li class="item item-detail">
                  <div>Account Created: {{userSubscription.startDate|date:'mediumDate'}}</div>
                </li>

                <li *ngIf="userSubscription.productCode === 'BJ-FREE-01'" class="item item-detail">
                  <div>Basic Activation: {{userSubscription.activationDate | date:'mediumDate'}}</div>
                </li>
                
                <li *ngIf="userSubscription.productCode === 'BJ-ANNUAL-01'" class="item item-detail">
                  <div>Gold Activation: {{userSubscription.activationDate | date:'mediumDate'}}</div>
                </li>
                
                <li *ngIf="userSubscription.productCode === 'BJ-MONTHLY-01'" class="item item-detail">
                  <div>Gold Activation: {{userSubscription.activationDate | date:'mediumDate'}}</div>
                </li>
                
                <li *ngIf="userSubscription.productCode === 'BJ-LIFETIME-01'" class="item item-detail">
                  <div>Lifetime Activation: {{userSubscription.activationDate | date:'mediumDate'}}</div>
                </li>
                
                <li *ngIf="userSubscription.productCode === 'BJ-MJ-ANNUAL-01'" class="item item-detail">
                  <div>Memories Activation: {{userSubscription.activationDate | date:'mediumDate'}}</div>
                </li>
                
                <!-- Default case if no product code matches -->
                <li *ngIf="!['BJ-FREE-01', 'BJ-ANNUAL-01', 'BJ-MONTHLY-01', 'BJ-LIFETIME-01', 'BJ-MJ-ANNUAL-01'].includes(userSubscription.productCode)" class="item item-detail">
                  <div>Basic Activation: {{userSubscription.activationDate | date:'mediumDate'}}</div>
                </li>
                
                <li *ngIf="userSubscription.expiryDate" class="item item-detail">
                  <div>Next Payment: {{userSubscription.expiryDate|date:'mediumDate'}}</div>
                </li>
                <li *ngIf="userSubscription.gatewayKey" class="item item-detail">
                  <div>Gateway: <strong>{{userSubscription.gatewayKey | titlecase}}</strong></div>
                </li>
              </ul>

              <div class="form-group mt-4">
               
                  <button class="btn btn-lg btn-block btn-gold max300-center"  (click)="openLink()">
                  Change Account
                </button>
              </div>

            </div>
          </div>

          <div class="card card-bio mt-5">
            <div class="card-body p-sm-5">

              <h2>Account Billing</h2>
             

              <div class="form-group mt-4">
                <button class="btn btn-lg btn-block btn-gold max300-center" 
                (click)="protectLink()">
                  Portal Sign In
                </button>
              </div>

            </div>
          </div>


          


          <div class="card card-bio mt-5">
            <div class="card-body p-sm-5">

              <h2>Delete My Account</h2>
              <p>
                Delete my account and all my Profiles, Memories, Things and Passwords.
              </p>

              <div class="form-group mt-4">
                <button class="btn btn-block btn-danger"
                        (click)="deleteAccount($event)">
                  Start process
                </button>
              </div>

            </div>
          </div>


        </div><!-- /col-md-4 -->
      </div>

    </div>
  </div>

</div><!--/container-->
